var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-body" }, [
    _c("div", { staticClass: "d-flex flex-column h-100" }, [
      _c(
        "div",
        { staticClass: "mb-4" },
        _vm._l(_vm.navbar, function(nav) {
          return _c(
            "ul",
            { key: nav.title, staticClass: "list-unstyled categories-list" },
            [
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "text-body d-flex align-items-center",
                      attrs: {
                        to: { name: nav.route },
                        href: "javascript: void(0);"
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "text-muted font-size-24 me-2",
                        class: nav.icon
                      }),
                      _c("span", { staticClass: "me-auto font-size-17" }, [
                        _vm._v(" " + _vm._s(nav.title))
                      ])
                    ]
                  )
                ],
                1
              )
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }