var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "d-xl-flex" }, [
        _c("div", { staticClass: "w-100" }, [
          _c("div", { staticClass: "d-md-flex row" }, [
            _c(
              "div",
              { staticClass: "card filemanager-sidebar col-md-3" },
              [_c("nav-bar")],
              1
            ),
            _c("div", { staticClass: "w-100 col-md-9" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", [
                    _c("div", { staticClass: "row mb-3" }, [
                      _c("div", { staticClass: "col-xl-3 col-sm-6" }, [
                        _c("div", { staticClass: "mt-2" }, [
                          _c("h5", [_vm._v(_vm._s(_vm.title))])
                        ])
                      ])
                    ])
                  ]),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "row" },
                      _vm._l(_vm.settings, function(settingSection) {
                        return _c(
                          "div",
                          {
                            key: settingSection.title,
                            staticClass: "col-lg-4"
                          },
                          [
                            _c(
                              "router-link",
                              { attrs: { to: { name: settingSection.route } } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass:
                                      "border border-primary text-center",
                                    attrs: {
                                      "header-class":
                                        "bg-transparent border-primary"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "header",
                                          fn: function() {
                                            return [
                                              _c(
                                                "h5",
                                                {
                                                  staticClass:
                                                    "my-0 text-primary"
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "settingIcon",
                                                    class: settingSection.icon
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("a", [
                                      _vm._v(_vm._s(settingSection.title))
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }